<ng2-toasty></ng2-toasty>  
<div class="jumbotron">

    <br/><br/><br/><br/>
<main class="content-wrapper"  id="closed"> 
    
        
        <section class="shop-checkouts spacegiven" >
            <div class="container">
                <div class="container-fluid">
                    <div class="main_content_container"> 
            
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h4 style="margin: 0;padding: 0;" class="page-title">Booking History</h4>
                </div>
            </div>
            <hr>
            
            <div class="row justify-content-md-center">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    
                    <div class="row justify-content-md-center" >
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12">

                        </div>
                        
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                            <div class="my_profile_setting_input form-group">
                                <label>From Date <span class="star"> *</span></label><br/>
                                
                                <p-calendar [(ngModel)]="StartDate" dateFormat="dd/mm/yyyy"  showButtonBar="true" (onSelect)="StartDateSelected(StartDate)" [readonlyInput]="true"  dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                            <div class="my_profile_setting_input form-group">
                                <label>To Date <span class="star"> *</span></label><br/>
                                <p-calendar [(ngModel)]="EndDate" [minDate]="StartDate" showButtonBar="true"  [readonlyInput]="true"  dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                            <div class="my_profile_setting_input form-group">
                                <label>Search By</label><br/>
                                <p-dropdown [options]="Basedons" [(ngModel)]="selectedBasedon" (onChange)="onChangeSearch($event)"  optionLabel="selectedBasedon"></p-dropdown>
                            </div>
                        </div>
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12">
                            <label>&nbsp;</label><br>
                            <button (click)="FnSearch()" pButton type="button" label="Search" class="ui-button-raised ui-button-info"></button>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                            <label>&nbsp;</label><br>
                            <button (click)="FnExcel()" pButton type="button" label="Download" class="ui-button-raised ui-button-info"></button>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <br>
                            <p-table #dt [columns]="cols" [value]="Orders"  sortMode="multiple" [resizableColumns]="true" [autoLayout]="true" [responsive]="true"  [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"> 
                            
                            <ng-template pTemplate="caption">
                                <div style="text-align: right">        
                                    <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                    <input type="text" pInputText size="20" [value]="dt.filters[Globalfiltertext] ? dt.filters[Globalfiltertext].value : ''" 
                                        [(ngModel)]="Globalfiltertext" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                                </div>
                            </ng-template>    
                            <ng-template pTemplate="header" let-columns>
                                    <tr>
                                      
                                        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                                            {{col.header}}
                                            <p-sortIcon [field]="col.field"></p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-columns="columns">
                                    <tr>
                                      
                                        <td *ngFor="let col of columns">
                                            <span class="ui-column-title">{{col.header}}</span> 
                                            <span *ngIf="col.header!='Ordered Items'" style="word-break: break-word;">{{rowData[col.field]}}</span>
    
    
    
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template  pTemplate="summary">
                                   <p style="text-align: center;" *ngIf="Orders.length==0">No records found </p>
                                </ng-template>
                            </p-table>
                       </div>
                    </div>
                  </div>
            </div>
           
            </div>
        </div>
    </div>
        </section>
        
    
</main>
</div>
<br/><br/><br/>