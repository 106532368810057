<div class="jumbotron">
<main class="content-wrapper" style="margin-top: 50px;background: jumbotron;border-radius: 8px;"> 
      
    <div class="container-fluid">
    <div class="main_content_container">
        <br> 
        <h4 style="text-align: center;color: black !important;">Welcome to Hummingbird Digital</h4>
        <section class="shop-checkouts spacegiven" >
            <div class="container">
                <!-- <div style="text-align: center;">
                    <a href="http://localhost:3000/api/login">Login with IDP Azure</a>
                    <button pButton type="button"  class="ui-button-info" label="Login" (click)="login()" >Login</button>
                </div>
                 -->
          
            </div>
        </section>
    </div>
    </div>
</main>
</div>