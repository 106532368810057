import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router,NavigationEnd   } from '@angular/router';
import { ToastyService, ToastyConfig, ToastOptions, ToastData } from 'ng2-toasty'; 
import { SpinnerVisibilityService } from 'ng-http-loader';
import { Httpclient } from '../_services/authorization.service';

declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements AfterViewInit {



  constructor(private http: Httpclient,
    private route: ActivatedRoute,
    private router: Router, 
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private spinner: SpinnerVisibilityService) { 
      // this.introJS.setOptions({
      //   steps: [
      //   {
      //      element: '#step1',
      //      intro: 'Year of 2021-22 Total Reservations,Billing & Invoices!',
      //      position: 'top'
      //   },
      //   {
      //      element: '#step2',
      //      intro: "Month of Feb Total Reservations,Billing & Invoices!",
      //      position: 'right'
      //   },],
      //   showProgress: true
      //  });
    }

  data: any;
  RowId:string;
  PropertyName:string;
  CurrentMonth:string;
  CurrentYear:string;
  YearReservations:string;
  YearBilling:string;
  MonthBilling:string;
  YearInvoices:string;
  YearPendingInvoices:string;
  MonthReservations:string;
  TodayCheckin:string;
  TodayCheckout:string;
  public href: string = "";
  YearInvoiceMapped:string;
  YearInvoiceUnMapped:string;
  MonthInvoiceMapped:string;
  MonthInvoiceUnMapped:string;
  ngOnInit() {
    
    // this.RowId = localStorage.getItem("_PropertyuniqueId"); 
   
    window.location.href ="https://hbexpenseanywhereuat.hummingbirdindia.com/hb/icicilogin";

    

   
  }

  menuclick(event)
  {
    debugger;
    if(event=="dashboard")
    {
      $('#dashboards').addClass("active");
      $('#Invoice').removeClass("active");
      $('#Bookings').removeClass("active");
      $('#TotalInvoice').removeClass("active");
      
    }
    else if(event =="billupload")
    {
      $('#dashboards').removeClass("active");
      $('#Invoice').addClass("active");
      $('#Bookings').removeClass("active");
      $('#TotalInvoice').removeClass("active");
    }
    else if(event == "bookings")
    {
      $('#dashboards').removeClass("active");
      $('#Invoice').removeClass("active");
      $('#Bookings').addClass("active");
      $('#TotalInvoice').removeClass("active");
    }
    else if(event == "invoices")
    {
      $('#dashboards').removeClass("active");
      $('#Invoice').removeClass("active");
      $('#Bookings').removeClass("active");
      $('#TotalInvoice').addClass("active");
    }

  }

  login()
  {
    window.location.href ="https://hbexpenseanywhereuat.hummingbirdindia.com/hb/icicilogin";
    // var Dtls = { 
    //   PropertyRowId:"Test",
    //   UserId: 1
    // }
    // return this.http.post("Test", Dtls).toPromise().then(res => {
    //   debugger;
    //   const parseJson =JSON.stringify(res);
    //   const parsedJson = JSON.parse(parseJson); 
    //   // window.location.href =parsedJson.login_url;
    //   // this.href == "/bookinghistory"
     
    // }).catch(err => {      
    //   this.spinner.hide(); 
    //   return err;
    // });
  }

  ngAfterViewInit() {

    
    
    
  }
  
}



