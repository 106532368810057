import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagelayout',
  templateUrl: './pagelayout.component.html',
  styleUrls: ['./pagelayout.component.css']
})
export class PagelayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
