import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router,NavigationEnd   } from '@angular/router';
import { ToastyService, ToastyConfig, ToastOptions, ToastData } from 'ng2-toasty'; 
import { SpinnerVisibilityService } from 'ng-http-loader';
import { Httpclient } from '../_services/authorization.service';
import { element } from 'protractor';
import { ConvertActionBindingResult } from '@angular/compiler/src/compiler_util/expression_converter';
import { parse } from 'querystring';
import { date } from 'azure-storage';
import * as introJs from 'intro.js/intro.js';

interface Basedon {
  name: string,
  selectedBasedon: string
}

declare var $: any;
declare var jQuery: any;
@Component({
  selector: 'app-mapping-invoice',
  templateUrl: './mapping-invoice.component.html',
  styleUrls: ['./mapping-invoice.component.css']
})
export class MappingInvoiceComponent implements OnInit {
 
  rangeDates: Date[];
  assigndate: Date[];
  logindetails: any; 
  Basedons: Basedon[];
  selectedBasedon: Basedon;
  NoticePeriod: any;
  StartDate: Date;
  EndDate:Date;
  UserId: string;
  Orders: any;
  cols: any;
  RowId:string;
  sessionIndex:string;
  currentRoute: any = [];
  InvoiceNo:string;
  InvoiceDate:Date;
  NoofDays:string;
  FileUrl:string;
  TotalSelectedPOAmount :string;
  Globalfiltertext:string;
  //URL1:string = "http://localhost:3000/api/imageupload1";
  URL1:string = "https://hbbill.staysimplyfied.com/api/imageupload1";
  FileName:string;
  uploadedFiles: any[] = [];
  InvoiceLocation:string;
  VendorTariff:string;
  VendorServiceAmount:string;
  CGSTAmount:string;
  SGSTAmount:string;
  VendorServiceCGST:string;
  VendorServiceSGST:string;
  TempVendorTariff:Number;
  TempVendorServiceAmount:Number;
  TempCGSTAmount:Number;
  TempSGSTAmount:Number;
  TempServiceCGSTAmount:Number;
  TempServiceSGSTAmount:Number;
  TotalInvoiceAmount:string;
  SelectedPoDetails:any[] = [];
  HotelGstNumber:string;
  HotelName:string;
  HotelAddress:string;
  HBGstNumber:string;
  HBName:string;
  HBAddress:string;
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
  constructor(private http: Httpclient,
    private route: ActivatedRoute,
    private router: Router, 
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private spinner: SpinnerVisibilityService) { 
     
    }

  ngOnInit(): void {
  
    var timeleft = 5;
  //   setTimeout(()=>{   
  //     introJs().setOptions({
  //       exitOnOverlayClick: true,
  //       showBullets: true,
  //       showStepNumbers: false,
  //     }).oncomplete(()=>{
  //       setTimeout(()=>{   
         
  //         $("#GSTHeader").hide();
       
  //         }, 5000);
  //         timeleft = 5;
  //     var downloadTimer = setInterval(function(){
  //   if(timeleft <= 0){
  //     clearInterval(downloadTimer);
  //    // document.getElementById("countdown").innerHTML = "";
  //   } else {
  //    // document.getElementById("countdown").innerHTML = timeleft + "";
  //   }
  //   timeleft -= 1;
  // }, 1000);
  //     })
  //     .onexit(function() {
  //       setTimeout(()=>{   
         
  //         $("#GSTHeader").hide();
       
  //         }, 5000);
  //         timeleft = 5;
  //     var downloadTimer = setInterval(function(){
  //   if(timeleft <= 0){
  //     clearInterval(downloadTimer);
  //    // document.getElementById("countdown").innerHTML = "";
  //   } else {
  //    // document.getElementById("countdown").innerHTML = timeleft + "";
  //   }
  //   timeleft -= 1;
  // }, 1000);
  //     }).start();
    
  //   }, 1000);
    
    
    this.TotalSelectedPOAmount = "0";
    this.TotalInvoiceAmount = "0";
    
    this.RowId = this.route.snapshot.paramMap.get('rowid'); 
    this.sessionIndex = this.route.snapshot.paramMap.get('sessionindex'); 
    alert("Welcome"+" "+this.RowId)
    if(this.RowId == null)
    {
      debugger;
      this.RowId = localStorage.getItem("_PropertyuniqueId"); 
    }
    
    localStorage.setItem("_PropertyuniqueId",this.RowId);
    if(this.RowId == "null" || this.RowId ==null )
    {
      window.location.href = "https://hbbill.staysimplyfied.com/#/error404";

    }
    this.spinner.show()
    this.Orders =[];

    this.cols = [
      { field: 'BookingCode', header: 'HB Booking Code' }, 
      { field: 'PropertyRef', header: 'Hotel Ref.No' },
      { field: 'POAmount_PerDay', header: 'Tariff / Day' }, 
      { field: 'StayDays', header: 'Stay Days' }, 
      { field: 'POAmount', header: 'Total Tariff' }, 
      { field: 'ServiceAmount', header: 'Total Food / Service' },       
      { field: 'BalanceAmount', header: 'Balance Value' },       
      { field: 'GuestName', header: 'Guest Name' },  
      { field: 'ChkOutStay', header: 'Stay Duration' },
   
  ];
  this.Basedons = [
    {name: 'Check-In Date', selectedBasedon: 'Check-In Date'},
    {name: 'Check-Out Date', selectedBasedon: 'Check-Out Date'}
    
   
];
this.selectedBasedon = 
  {name: 'Check-In Date', selectedBasedon: 'Check-In Date'}

  
    var mapDtls = { 
      FromDt: "",
      ToDt: "",
      RowId:this.RowId,
      Basedon:this.selectedBasedon.selectedBasedon,
      UserId: this.UserId
    }
    setTimeout(()=>{   
    this.http.post("getbookingdetails", mapDtls).toPromise().then(res => {
      
      const parseJson =JSON.stringify(res);
      const parsedJson = JSON.parse(parseJson);  
      this.Orders = parsedJson.PoDetails[0];
      this.HotelName = parsedJson.PoDetails[1][0].PropertyName;
      this.HotelGstNumber = parsedJson.PoDetails[1][0].GSTNumber;
      this.HotelAddress = parsedJson.PoDetails[1][0].Propertaddress;

      this.HBName = parsedJson.PoDetails[2][0].CompanyName;
      this.HBGstNumber = parsedJson.PoDetails[2][0].GSTNumber;
      this.HBAddress = parsedJson.PoDetails[2][0].HBAddress;
      this.spinner.hide();
    }).catch(err => {      
      this.spinner.hide(); 
      return err;
    });
  }, 200);

  


  }

  LogoutClear()
  {

    var Item ={
      user_id:this.RowId,
      session_index:this.sessionIndex
    }

    return this.http.post("logout", Item).toPromise().then(res => {
      debugger;
      alert("Logout Success");
    })
    
  }
  
  FnSearch() {
    this.Orders = [];
    this.SelectedPoDetails = [];
    this.TotalSelectedPOAmount = "0";
    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 3000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    toastOptions.msg="";
    
    if( this.StartDate == undefined) { 
      toastOptions.msg += 'From Date is required' + '</br>';
    }
    if( this.EndDate == undefined) { 
      toastOptions.msg += 'To Date is required' + '</br>';
    }
   
    if (toastOptions.msg != "") { 
      this.toastyService.error(toastOptions);
    }
    else { 
     
      var dd = this.StartDate.getDate();
      var mm = this.StartDate.getMonth()+1;
      var yy = this.StartDate.getFullYear();
      var StartDate =dd +"/" + mm+"/" + yy;
      var dd = this.EndDate.getDate();
      var mm = this.EndDate.getMonth()+1;
      var yy = this.EndDate.getFullYear();
      var EndDate =dd +"/" + mm+"/" + yy;
    var mapDtls = { 
      FromDt: StartDate,
      ToDt: EndDate,
      RowId:this.RowId,
      Basedon:this.selectedBasedon.selectedBasedon,
      UserId: this.UserId
    }
    
    return this.http.post("getbookingdetails", mapDtls).toPromise().then(res => {
      
      const parseJson =JSON.stringify(res);
      const parsedJson = JSON.parse(parseJson);  
      this.Orders = parsedJson.PoDetails[0];
      this.spinner.hide();
    }).catch(err => {      
      this.spinner.hide(); 
      return err;
    });
    }
  }
  
  TotalInvoice() 
  {
    debugger;
    this.TotalInvoiceAmount = "0";
    this.TempVendorTariff = parseFloat(this.VendorTariff);
    this.TempVendorServiceAmount = parseFloat(this.VendorServiceAmount);
    this.TempSGSTAmount = parseFloat(this.SGSTAmount);
    this.TempCGSTAmount = parseFloat(this.CGSTAmount);
    this.TempServiceCGSTAmount = parseFloat(this.VendorServiceCGST);
    this.TempServiceSGSTAmount = parseFloat(this.VendorServiceSGST);
    if(this.VendorTariff == undefined || this.VendorTariff == "")
    {
      this.TempVendorTariff = 0;
    }
    if(this.VendorServiceAmount == undefined || this.VendorServiceAmount == "")
    {
      this.TempVendorServiceAmount = 0;
    }
    if(this.SGSTAmount == undefined || this.SGSTAmount =="")
    {
      this.TempSGSTAmount = 0;
    }
    if(this.CGSTAmount == undefined || this.CGSTAmount =="")
    {
      this.TempCGSTAmount = 0;
    }
    if(this.VendorServiceCGST == undefined || this.VendorServiceCGST =="")
    {
      this.TempServiceCGSTAmount = 0;
    }
    if(this.VendorServiceSGST == undefined || this.VendorServiceSGST == "")
    {
      this.TempServiceSGSTAmount = 0;
    }
    if( this.TempVendorTariff== undefined || this.TempVendorTariff.toString() =="")
    {
      this.TempVendorTariff = 0;
    }
    if(this.TempVendorServiceAmount == undefined || this.TempVendorServiceAmount.toString() =="")
    {
      this.TempVendorServiceAmount = 0;
    }
    if(this.TempSGSTAmount == undefined || this.TempSGSTAmount.toString() == "")
    {
      this.TempSGSTAmount = 0;
    }
    if(this.TempCGSTAmount == undefined || this.TempCGSTAmount.toString() == "")
    {
      this.TempCGSTAmount = 0;
    }
    var Tariff = parseFloat(this.TempVendorTariff.toString());
    var ServiceAmount = parseFloat(this.TempVendorServiceAmount.toString());
    var SGSTAmt = parseFloat(this.TempSGSTAmount.toString());
    var CGSTAmt = parseFloat(this.TempCGSTAmount.toString());
    var ServiceCGSTAmt = parseFloat(this.TempServiceCGSTAmount.toString());
    var ServiceSGSTAmt = parseFloat(this.TempServiceSGSTAmount.toString());
    this.TotalInvoiceAmount = (Tariff+ServiceAmount+SGSTAmt+CGSTAmt+ServiceCGSTAmt+ServiceSGSTAmt).toString();
  }
  StartDateSelected(StartDate)
  {
    let datePlus = new Date(StartDate).getDate() + 1;
    this.EndDate = new Date(StartDate);
    this.EndDate.setDate(datePlus)

  }

  onBasicUploadAutoPdf(event) {
    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 3000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    toastOptions.msg = "";
    this.FileUrl = event.originalEvent.body.path;
    this.FileName = this.FileUrl.replace('https://uploadinvoices.blob.core.windows.net/hotelmappinginvoices/','');

    toastOptions.msg += 'Invoice uploaded successfully' + '</br>';
    this.toastyService.success(toastOptions);
  }
  onBasicUploadAutoPdfCheck(event) {
    
    var toastOptions:ToastOptions = {
      title: "Alert", 
      showClose: true,
      timeout: 3000,
      theme: 'material',
      onAdd: (toast:ToastData) => {
      },
      onRemove: function(toast:ToastData) {
      }
  };
  toastOptions.msg=""; 
    if(event.files[0].type =='application/pdf' || event.files[0].type =='image/jpeg' || event.files[0].type =='image/png'  ) {
      
    } 
    else {
      toastOptions.msg += 'File type is invalid' + '</br>';
    }

    if (toastOptions.msg != "") {
      this.toastyService.error(toastOptions); 
    }
  }

  onSelect(){
    debugger;
    if(this.rangeDates[1] !==null)
    {
      
      $("#closed").trigger("click");
    }
    
  }

  SelectRow(event)
  {   
    debugger;
      for(var i=0;i< this.Orders.length;i++)
      {

       if(this.Orders[i].BookingId == event.BookingId && this.Orders[i].GuestId == event.GuestId && event.Chk == false)
        {
          this.Orders[i].Chk = true;
        }
        else if(this.Orders[i].BookingId == event.BookingId && this.Orders[i].GuestId == event.GuestId && event.Chk == true)
        {
          this.Orders[i].Chk = false;
        }
      }
      this.TotalSelectedPOAmount = "0";
      this.SelectedPoDetails = [];
      for(var i =0;i < this.Orders.length;i++)
     {   
        if(this.Orders[i].Chk == true)
        {
          this.TotalSelectedPOAmount = (parseFloat(this.TotalSelectedPOAmount)+ parseFloat(this.Orders[i].BalanceAmount)).toString();
          this.SelectedPoDetails.push(this.Orders[i]);
        }
      }
  
  }

  MappingSave()
  {
    debugger;
    var toastOptions:ToastOptions = {
      title: "Alert", 
      showClose: true,
      timeout: 10000,
      theme: 'material',
      onAdd: (toast:ToastData) => {
      },
      onRemove: function(toast:ToastData) {
      }
  };
  toastOptions.msg="";
  
  if (this.InvoiceNo == undefined || this.InvoiceNo.trim() == '') {
    toastOptions.msg += 'Invoice No is required' + '</br>';
  }
  if (this.InvoiceDate == undefined) {
    toastOptions.msg += 'Invoice Date is required' + '</br>';
  }
  if (this.NoofDays == undefined || this.NoofDays.trim() == '') {
    toastOptions.msg += 'No.of Stay Days is required' + '</br>';
  }
  var tt= parseFloat(this.NoofDays);
  if(tt < 1)
  {
    toastOptions.msg += 'No.of Stay Days is minimum 1 day required' + '</br>';
  }
  if (this.FileUrl == undefined || this.FileUrl.trim() == '') {
    toastOptions.msg += 'Invoice Upload is required' + '</br>';
  }
  
  if(this.VendorTariff==null || this.VendorTariff==undefined) {
    toastOptions.msg += 'Total Tariff is required' + '</br>';
  }
  
  
  if (this.VendorServiceAmount == null || this.VendorServiceAmount == undefined || this.VendorServiceAmount == "") {
    this.VendorServiceAmount = "0";
  }
  
  
  if (this.CGSTAmount == null || this.CGSTAmount == undefined) {
    toastOptions.msg += 'Total CGST is required' + '</br>';
  }
  if (this.SGSTAmount == null || this.SGSTAmount == undefined) {
    toastOptions.msg += 'Total SGST is required' + '</br>';
  }
  
  this.SelectedPoDetails = [];
  for(var i =0;i < this.Orders.length;i++)
  {
   
    if(this.Orders[i].Chk == true)
    {
      this.SelectedPoDetails.push(this.Orders[i]);
    }
  }
  if(this.SelectedPoDetails.length ==0)
  {
    toastOptions.msg += 'Please select minimum one Reservation details' + '</br>';
  }
  if (toastOptions.msg != "") {
        this.toastyService.error(toastOptions);
      }
  else {
    var TotalTar = (parseFloat(this.VendorTariff.toString())+parseFloat(this.SGSTAmount.toString())+parseFloat(this.VendorServiceAmount.toString())+parseFloat(this.CGSTAmount.toString())+parseFloat(this.VendorServiceCGST.toString())+parseFloat(this.VendorServiceSGST.toString()))
  
    let addDate = new Date();
    addDate.setDate(this.InvoiceDate.getDate());
        this.InvoiceDate  = addDate;
        var SelectedDtls = {
          InvoiceNo:this.InvoiceNo,
          InvoiceDate:this.InvoiceDate,
          NoofDays:this.NoofDays,
          FilePath:this.FileUrl,
          VendorTariff:this.VendorTariff,
          VendorService:this.VendorServiceAmount,
          VendorTax: 0,
          AdvanceadjustmentFlag:false, 
          SGST:this.SGSTAmount,
          CGST:this.CGSTAmount,
          InvoiceAmount:TotalTar,
          TotalPOAmount:TotalTar,
          IGST:0,
          PropertyRowId:this.RowId,
          SelectedPoDtls:this.SelectedPoDetails,
          ServiceCGSTAmount:this.VendorServiceCGST,
          ServiceSGSTAmount:this.VendorServiceSGST
        }
        return this.http.post("mappingsave", SelectedDtls).toPromise().then(res => {
          
          const parseJson =JSON.stringify(res);
          const parsedJson = JSON.parse(parseJson);  
          if(parsedJson.message=="Submitted Successfully") {
            
            toastOptions.msg="Your Invoice has been submitted successfully, we will verify and update shortly";
            this.toastyService.success(toastOptions);
           this.InvoiceNo ="";
           this.InvoiceDate = new Date();
           this.NoofDays = "";
           this.FileUrl ="";
           this.VendorTariff = "";
           this.VendorServiceAmount ="";
           this.SGSTAmount = "";
           this.CGSTAmount ="";
           this.VendorServiceCGST ="";
           this.VendorServiceSGST = "";
           this.SelectedPoDetails =[];
           this.Orders =[];
           this.rangeDates =this.assigndate;
           this.FileName ="";
           this.TotalInvoiceAmount ="0";
           this.TotalSelectedPOAmount ="0";
           var mapDtls = { 
            FromDt: "",
            ToDt: "",
            RowId:this.RowId,
            Basedon:this.selectedBasedon.selectedBasedon,
            UserId: this.UserId
          }
          setTimeout(()=>{   
          this.http.post("getbookingdetails", mapDtls).toPromise().then(res => {
            
            const parseJson =JSON.stringify(res);
            const parsedJson = JSON.parse(parseJson);  
            this.Orders = parsedJson.PoDetails[0];
            this.HotelName = parsedJson.PoDetails[1][0].PropertyName;
            this.HotelGstNumber = parsedJson.PoDetails[1][0].GSTNumber;
            this.HotelAddress = parsedJson.PoDetails[1][0].Propertaddress;
      
            this.HBName = parsedJson.PoDetails[2][0].CompanyName;
            this.HBGstNumber = parsedJson.PoDetails[2][0].GSTNumber;
            this.HBAddress = parsedJson.PoDetails[2][0].HBAddress;
            this.spinner.hide();
          }).catch(err => {      
            this.spinner.hide(); 
            return err;
          });
        }, 100);
          } 
          this.spinner.hide();
        }).catch(err => {
          this.spinner.hide();
          toastOptions.msg = "An error occurred while saving the record.";
          this.toastyService.error(toastOptions); 
          return err;
        });
  
      } 
  }

  MappingClear()
  {
    this.spinner.show();
    this.InvoiceNo ="";
           this.InvoiceDate = new Date();
           this.NoofDays = "";
           this.FileUrl ="";
           this.VendorTariff ="";
           this.VendorServiceAmount ="";
           this.SGSTAmount = "";
           this.CGSTAmount ="";
           this.VendorServiceCGST ="";
           this.VendorServiceSGST = "";
           this.SelectedPoDetails =[];
           this.Orders =[];
           this.rangeDates =this.assigndate;
           this.FileName ="";
           this.TotalInvoiceAmount ="0";
           this.StartDate = new Date();
           this.EndDate = new Date();
           this.TotalSelectedPOAmount ="0";

           var mapDtls = { 
            FromDt: "",
            ToDt: "",
            RowId:this.RowId,
            Basedon:this.selectedBasedon.selectedBasedon,
            UserId: this.UserId
          }
          setTimeout(()=>{   
          this.http.post("getbookingdetails", mapDtls).toPromise().then(res => {
            
            const parseJson =JSON.stringify(res);
            const parsedJson = JSON.parse(parseJson);  
            this.Orders = parsedJson.PoDetails[0];
            this.HotelName = parsedJson.PoDetails[1][0].PropertyName;
            this.HotelGstNumber = parsedJson.PoDetails[1][0].GSTNumber;
            this.HotelAddress = parsedJson.PoDetails[1][0].Propertaddress;
      
            this.HBName = parsedJson.PoDetails[2][0].CompanyName;
            this.HBGstNumber = parsedJson.PoDetails[2][0].GSTNumber;
            this.HBAddress = parsedJson.PoDetails[2][0].HBAddress;
            this.spinner.hide();
          }).catch(err => {      
            this.spinner.hide(); 
            return err;
          });
        }, 100);
  }
}
