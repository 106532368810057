<ng2-toasty></ng2-toasty>  
<div class="jumbotron">


<main class="content-wrapper"  id="closed"> 
    <div class="container-fluid">
    <div class="main_content_container"> 
        
        <br/><br/>
      <p>Welcome Mr.{{RowId}}</p>
            <div class="container" id = "GSTHeader">
            <div class="row ">
                <button pButton type="button"  class="ui-button-info" label="Logout" (click)="LogoutClear()" ></button>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" >
                    <section class="shop-checkouts spacegiven" style="min-height: 165px;">
                        <div style="margin-left: 5%;">
                    <label style="font-weight: 600;"> &nbsp;&nbsp;&nbsp;</label>&nbsp;&nbsp; <label class="colors">{{HotelName}}</label><br/>
                    <label style="font-weight: 600;">GST Number </label>&nbsp;: <label class="ClrGst">{{HotelGstNumber}}</label><br/>
                    <label style="font-weight: 600;">Address &nbsp;: <span class="ClrAdress">{{HotelAddress}}</span></label>
                </div>
                </section>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <section class="shop-checkouts spacegiven" style="min-height: 165px;" >
                        <div style="margin-left: 5%;">
                    <label style="font-weight: 600;">&nbsp;&nbsp; &nbsp;</label>&nbsp; <label class="colors"> {{HBName}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span  id="countdown"></span></label><br/>                    
                    <label style="font-weight: 600;">GST Number </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: <label class="ClrGst"> {{HBGstNumber}}</label><br/>
                    <label style="font-weight: 600;">Address : <span class="ClrAdress">{{HBAddress}}</span></label>
                    </div>
                </section>
                </div>
            </div>
            </div>
            <br/>
            <section class="shop-checkouts spacegiven" >
                <div class="container">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" data-title="Welcome" data-intro="Fill your Invoice Details & Upload Invoice." data-position="right">
                <h4>Invoice Details</h4>
            <hr/>
                    <div class="row" >
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12">
                            </div>
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                            <div class="my_profile_setting_input form-group">
                                <label>Invoice No  <span class="star"> *</span></label> 
                                <input type="text" placeholder="Invoice No" class="form-control" name="InvoiceNo" [(ngModel)]="InvoiceNo">
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                            <div class="my_profile_setting_input form-group">
                                <label>Invoice Date <span class="star"> *</span></label><br/>
                                <p-calendar [(ngModel)]="InvoiceDate" placeholder="Invoice Date" showButtonBar="true"  [readonlyInput]="true"  dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                            <div class="my_profile_setting_input form-group">
                                <label>No.of Stay Days<span class="star"> *</span></label> 
                                <input type="text" class="form-control" placeholder="No.of Stay Days" maxlength="2" pKeyFilter="int" name="NoofDays" [(ngModel)]="NoofDays" >
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12" data-title="Upload Invoice" data-intro="Don't forget to Upload the Invoice." data-position="right" >
                            <label >Invoice Upload ( jpg / png / pdf )<span class="star"> *</span></label><br>
                            
                            <p-fileUpload #fubauto mode="basic" name="file" url="{{URL1}}"
                            accept="application/pdf,image/*"
                            (onUpload)="onBasicUploadAutoPdf($event)" (onSelect)="onBasicUploadAutoPdfCheck($event)" [auto]="true"
                            chooseLabel="Browse" ></p-fileUpload> <br/>
                            <label style="max-width:250px;" >{{FileName}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12">
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                            <div class="my_profile_setting_input form-group">
                                <label>Total Tariff<span class="star"> *</span></label> 
                                <input type="text" id="VendorTariff" [(ngModel)]="VendorTariff"  maxlength="10"  class="form-control input-sm" placeholder="Total Tariff" (input)="TotalInvoice()" appTwoDigitDecimaNumber>
                                <!-- <input type="text" class="form-control" maxlength="10"  (input)="TotalInvoice()"  name="VendorTariff" [(ngModel)]="VendorTariff"> -->
                            </div>
                        </div>
                        
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                            <div class="my_profile_setting_input form-group">
                                <label>Total Tariff CGST Amount<span class="star"> *</span></label> 
                                <input type="text" id="CGSTAmount" [(ngModel)]="CGSTAmount"  class="form-control input-sm" placeholder="Total CGST" (input)="TotalInvoice()" appTwoDigitDecimaNumber>
                                <!-- <input type="text" class="form-control" maxlength="10"  (input)="TotalInvoice()" pKeyFilter="money" name="CGSTAmount" [(ngModel)]="CGSTAmount"> -->
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                            <div class="my_profile_setting_input form-group">
                                <label>Total Tariff SGST Amount<span class="star"> *</span></label> 
                                <input type="text" id="SGSTAmount" [(ngModel)]="SGSTAmount" maxlength="10"  class="form-control input-sm" placeholder="Total SGST" (input)="TotalInvoice()" appTwoDigitDecimaNumber>
                                <!-- <input type="text" class="form-control" maxlength="10"  (input)="TotalInvoice()" pKeyFilter="money" name="SGSTAmount" [(ngModel)]="SGSTAmount"> -->
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <!-- <div class="my_profile_setting_input form-group" style="text-align: center;font-weight: bold;">
                                <label>Total Invoice Value</label> <br/>
                                <label style="color:#00B98E !important;font-weight: bold;text-align: center;">{{TotalInvoiceAmount | number : '1.2-2'}}</label>
                            </div> -->
                        </div>
                        
                        
                    
                    </div>

                    <div class="row">
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12">
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                            <div class="my_profile_setting_input form-group">
                                <label>Total Food / Service Amount</label> 
                                <input type="text" id="VendorServiceAmount"  maxlength="10" [(ngModel)]="VendorServiceAmount"  class="form-control input-sm" placeholder="Total Food / Service" (input)="TotalInvoice()" appTwoDigitDecimaNumber>
                                
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                            <div class="my_profile_setting_input form-group">
                                <label>Total Food / Service CGST Amount</label> 
                                <input type="text" id="VendorServiceCGST"  maxlength="10" [(ngModel)]="VendorServiceCGST"  class="form-control input-sm" placeholder="Total Service CGST" (input)="TotalInvoice()" appTwoDigitDecimaNumber>
                                
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                            <div class="my_profile_setting_input form-group">
                                <label>Total Food / Service SGST Amount</label> 
                                <input type="text" id="VendorServiceSGST" [(ngModel)]="VendorServiceSGST"  class="form-control input-sm" placeholder="Total Service SGST" (input)="TotalInvoice()" appTwoDigitDecimaNumber>
                                <!-- <input type="text" class="form-control" maxlength="10"  (input)="TotalInvoice()" pKeyFilter="money" name="CGSTAmount" [(ngModel)]="CGSTAmount"> -->
                            </div>
                        </div>
                        
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                            <div class="my_profile_setting_input form-group" style="text-align: center;font-weight: bold;">
                                <label>Total Invoice Value</label> <br/>
                                <label style="color:#00B98E !important;font-weight: bold;text-align: center;">{{TotalInvoiceAmount | number : '1.2-2'}}</label>
                            </div>
                        </div>
                        
                        
                    
                    </div>
            </div>
            </div>
            </section>
            <br/>
            <section class="shop-checkouts spacegiven" >
                <div class="container">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" style="float: left;">
                                <h4>Reservation Details</h4>
                            </div>
                            <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12" >
                            </div>
                            <div  class="col-lg-3 col-md-3 col-sm-3 col-xs-12"  style="float: right;font-weight: bold;padding-left: 7%;padding-top: 1%;">
                                <label>Total Value</label> 
                            <label style="color:#00B98E !important;margin-left: 10px;">{{TotalSelectedPOAmount | number : '1.2-2'}}</label>
                            </div>
                        </div>
                       
                        
                    <hr/>
            <div class="row justify-content-md-center">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    
                    <!-- <div class="row justify-content-md-center" >
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 dtrange">
                            <label>Date Range <span class="star"> *</span></label> 
                            <p-calendar [(ngModel)]="rangeDates"  showButtonBar="true"  [style]="{'width': '100%'}" selectionMode="range" [readonlyInput]="true" [numberOfMonths]="2" dateFormat="dd/mm/yy" [showIcon]="true" (onSelect)="onSelect()"></p-calendar>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                            <label>&nbsp;</label><br>
                            <button (click)="FnSearch()" pButton type="button" label="Search" class="ui-button-raised ui-button-info"></button>
                        </div>
                    </div> -->
                    <div class="row justify-content-md-center" >
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">

                        </div>
                        <!-- <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                            <div class="my_profile_setting_input form-group">
                                <label>From Date <span class="star"> *</span></label><br/>
                                
                                <p-calendar [(ngModel)]="StartDate"  showButtonBar="true"  [readonlyInput]="true"  (onSelect)="StartDateSelected(StartDate)"  dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                            <div class="my_profile_setting_input form-group">
                                <label>To Date <span class="star"> *</span></label><br/>
                                <p-calendar [(ngModel)]="EndDate" [minDate]="StartDate" showButtonBar="true"  [readonlyInput]="true"  dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                           
                            <div class="my_profile_setting_input form-group">
                                <label>Search By</label><br/>
                                <p-dropdown [options]="Basedons" [(ngModel)]="selectedBasedon"  optionLabel="selectedBasedon"></p-dropdown>
                            </div>
                        </div>
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12">
                            <label>&nbsp;</label><br>
                            <button (click)="FnSearch()" pButton type="button" label="Search" class="ui-button-raised ui-button-info"></button>
                        </div> -->
                        <!-- <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                            <div class="my_profile_setting_input form-group">
                                
                                <div  style="text-align: center;font-weight: bold;">
                                    <label>Total PO Value</label><br/>
                                <label style="color:#00B98E !important;;">{{TotalSelectedPOAmount | number : '1.2-2'}}</label>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" data-title="Reservation Details" data-intro="Select one or multiple reservations for above uploaded Invoice">
                            <br>
                            <p-table #dt [columns]="cols" [value]="Orders" [resizableColumns]="true" [autoLayout]="true" [responsive]="true"  [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"> 
                            <ng-template pTemplate="caption">
                                <div style="text-align: right">        
                                    <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                    <input type="text" pInputText size="25" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                                </div>
                            </ng-template>    
                            <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th style="width: 3.25em">
                                            <label></label>
                                        </th>
                                        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                                            {{col.header}}
                                            <p-sortIcon [field]="col.field"></p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-columns="columns">
                                    <tr>
                                        <td>
                                            <p-tableCheckbox [value]="rowData" (click)="SelectRow(rowData)"></p-tableCheckbox>
                                        </td>
                                        <td *ngFor="let col of columns">
                                            <span class="ui-column-title">{{col.header}}</span> 
                                            <span *ngIf="col.header!='Ordered Items'" style="word-break: break-word;text-align: center;">{{rowData[col.field]}}</span>
    
                                            <button *ngIf="col.header=='Ordered Items'" (click)="FnViewCart(rowData[col.field])" pButton type="button" icon="pi pi-eye" class="ui-button-info" title="View"></button>
    
    
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template  pTemplate="summary">
                                   <p style="text-align: center;" *ngIf="Orders.length==0">No records found </p>
                                </ng-template>
                            </p-table>
                       </div>
                    </div>
                  </div>
            </div>
                </div>
                </div>
            </section>
            
            
            <section class="shop-checkouts spacegiven" >
                <div class="container">
                    <div class="row">
                        <div class="col-lg-5 col-md-5 col-sm-5 col-xs-4">

                        </div>
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-4" data-title="Done" data-intro="Submit your invoice, we will verify and update shortly ">
                            
                            <button pButton type="button"  class="ui-button-success" label="Submit" (click)="MappingSave()" ></button>
                        </div>
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-4">
                            <button pButton type="button"  class="ui-button-info" label="Clear" (click)="MappingClear()" ></button>
                        </div>
                        </div>
                        </div>
                        </section>
            

        
</div>
    </div>
</main>

</div>
<br/><br/><br/>