import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router,NavigationEnd   } from '@angular/router';
import { ToastyService, ToastyConfig, ToastOptions, ToastData } from 'ng2-toasty'; 
import { SpinnerVisibilityService } from 'ng-http-loader';
import { Httpclient } from '../_services/authorization.service';
var FileSaver = require('file-saver');
import * as introJs from 'intro.js/intro.js';
declare var $: any;
interface Basedon {
  name: string,
  selectedBasedon: string
}
@Component({
  selector: 'app-bookinghistory',
  templateUrl: './bookinghistory.component.html',
  styleUrls: ['./bookinghistory.component.css']
})
export class BookinghistoryComponent implements OnInit {
  rangeDates: Date[];
  assigndate: Date[];
  StartDate: Date;
  EndDate:Date;
  Basedons: Basedon[];
  selectedBasedon: Basedon;
  UserId: string;
  Orders: any;
  cols: any;
  RowId:string;
  results: any = [] = [];
  currentRoute: any = [];
  Globalfiltertext:string ='';
  constructor(private http: Httpclient,
    private route: ActivatedRoute,
    private router: Router, 
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private spinner: SpinnerVisibilityService) { 
     
    }

  ngOnInit(): void {
   
    this.RowId = localStorage.getItem("_PropertyuniqueId"); 
           
    if(this.RowId == "null" || this.RowId ==null )
    {
      window.location.href = "https://hbbill.staysimplyfied.com/#/error404";

    }
    this.Orders =[];
    this.cols = [
      { field: 'BookingCode', header: 'Booking Code / Hotel Ref No' }, 
      { field: 'GuestName', header: 'Guest Name' }, 
      { field: 'RoomType', header: 'Room Type' },  
      { field: 'AgreedTariff', header: 'Agreed Tariff' },  
      
      { field: 'ChkInDt', header: 'Check-In Date' },
      { field: 'ChkOutDt', header: 'Check-out Date' },
      { field: 'CurrentStatus', header: 'Current Status' },
   
  ];
  this.Basedons = [
    {name: 'Check-In Date', selectedBasedon: 'Check-In Date'},
    {name: 'Check-Out Date', selectedBasedon: 'Check-Out Date'}
    
   
];
this.selectedBasedon = 
  {name: 'Check-In Date', selectedBasedon: 'Check-In Date'}




  this.StartDate = new Date();
  let datePlus = new Date().getDate();
  let datePlus1 = new Date().getDate() -1;
      this.EndDate = new Date();
      this.EndDate.setDate(datePlus);
      this.StartDate.setDate(datePlus1); 
  
  }

  FnSearch() {
    
   
    
    
    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 3000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    toastOptions.msg="";
    
    if( this.StartDate == undefined) { 
      toastOptions.msg += 'From Date is required' + '</br>';
    }
   
      if(this.EndDate==null || this.EndDate==undefined) {
        toastOptions.msg += 'End Date is required' + '</br>';
      }
    

    if (toastOptions.msg != "") { 
      this.toastyService.error(toastOptions);
    }
    else { 
     
      var dd = this.StartDate.getDate();
      var mm = this.StartDate.getMonth()+1;
      var yy = this.StartDate.getFullYear();
      var StartDate =dd +"/" + mm+"/" + yy;
      var dd = this.EndDate.getDate();
      var mm = this.EndDate.getMonth()+1;
      var yy = this.EndDate.getFullYear();
      var EndDate =dd +"/" + mm+"/" + yy;
      
    var bkDtls = { 
      StartDate: StartDate,
      EndDate: EndDate,
      PropertyRowId:this.RowId,
      Basedon:this.selectedBasedon.selectedBasedon,
      UserId: 1
    }
    
    return this.http.post("bookinghistories", bkDtls).toPromise().then(res => {
      debugger;
      const parseJson =JSON.stringify(res);
      const parsedJson = JSON.parse(parseJson);  
      this.Orders = parsedJson.Bookinghistory;
      this.spinner.hide();
    }).catch(err => {      
      this.spinner.hide(); 
      return err;
    });
    }
  }

  onSelect(){
    debugger;
    if(this.rangeDates[1] !==null)
    {
      
      $("#closed").trigger("click");
    }
    
  }
  onChangeSearch(event)
  {
    this.Orders = [];
  }
  FnExcel()
  {
   
    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 3000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    toastOptions.msg="";
    
    if( this.StartDate == undefined) { 
      toastOptions.msg += 'From Date is required' + '</br>';
    }
    if(this.EndDate==null || this.EndDate==undefined) {
      toastOptions.msg += 'End Date is required' + '</br>';
    }

    if (toastOptions.msg != "") { 
      this.toastyService.error(toastOptions);
    }
    else { 
     
    debugger;
    var dd = this.StartDate.getDate();
    var mm = this.StartDate.getMonth()+1;
    var yy = this.StartDate.getFullYear();
    var StartDate =dd +"/" + mm+"/" + yy;
    var dd = this.EndDate.getDate();
    var mm = this.EndDate.getMonth()+1;
    var yy = this.EndDate.getFullYear();
    var EndDate =dd +"/" + mm+"/" + yy;
    var bkDtls = { 
      StartDate: StartDate,
      EndDate: EndDate,
      PropertyRowId:this.RowId,
      Basedon:this.selectedBasedon.selectedBasedon,
      UserId: 1
    }
    
    return this.http.post("bookinghistorydownload", bkDtls).toPromise().then(data => {
      debugger;
      this.results = data;
      var res = this.results.Result; 
      var resFileName = this.results.FileName;
      FileSaver.saveAs(res, resFileName);
      this.spinner.hide();
    }).catch(err => {   
      var toastOptions1: ToastOptions = {
        title: "Alert",
        showClose: true,
        timeout: 3000,
        theme: 'material',
        onAdd: (toast: ToastData) => {
        },
        onRemove: function (toast: ToastData) {
        }
      };   
      this.spinner.hide(); 
      toastOptions1.msg ="No records found";
      this.toastyService.error(toastOptions1);
     
    });
    }
  }
  StartDateSelected(StartDate)
  {
    let datePlus = new Date(StartDate).getDate() + 1;
    if(this.StartDate > this.EndDate)
    {
      debugger;
      this.EndDate = new Date(StartDate);
      this.EndDate.setDate(datePlus)
    }
    else
    {
      
      let datePlus1 = new Date().getDate();
      this.EndDate.setDate(datePlus1)
    }
 
  }
}
